import React from 'react';

const MyProfile = () => {
    return (
        <div>
            <h2>My Profile</h2>
        </div>
    );
};

export default MyProfile;