import React from 'react';

const PrimaryButton = () => {
    return (
        <div>
        <button className="btn btn-primary uppercase text-white font-bold bg-gradient-to-r from-secondary to-primary">
          Get Started
        </button>
      </div>
    );
};

export default PrimaryButton;