import React from 'react';

const MyReview = () => {
    return (
        <div>
            <h2>My Review</h2>
        </div>
    );
};

export default MyReview;