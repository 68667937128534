import React from 'react';
import NotFoundImg from '../../../assets/Images/404-error.gif'
const NotFound = () => {
    return (
        <div>
        <div>
            <img className='w-100' src={NotFoundImg} alt="" srcset="" />
        </div>
        </div>
    );
};

export default NotFound;