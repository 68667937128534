import React from 'react';
import SingleProductDetails from '../SingleProductDetails/SingleProductDetails';

const Purchase = () => {
    return (
            <div>
                <div><SingleProductDetails></SingleProductDetails></div>
            </div>
    );
};

export default Purchase;